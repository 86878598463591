"use client";
import React, { useEffect, useState } from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import "./HomeHeader.scss";

interface HomeHeaderProps {
    headers: {
      id: number;
      title: string;
      subtitle: string;
      description: string;
      buttons: any[];
      gradient: string;
    }[];
}
const HomeHeader = () => {
  const [gradientPosition, setGradientPosition] = useState("translateX(0vw)");
  const [headerIdx, setHeaderIdx] = useState(0);
  const [bottomBgOffset, setBottomBgOffset] = useState('-100vw');
  const [swiper, setSwiper] = useState<any>(null);


  const headers : {
    id: number;
    title: string;
    subtitle: string;
    gradient: string;
    short: string;
  }[] = [
    {
      short: "App",
      title: "Création de vos applications web, mobile, data.",
      subtitle: "Nous sommes une agence tech de passionés qui vous accompagnent dans la réalisation de tous vos projets.",
    },
    {
      short: "Digitalisation",
      title: "Digitalisation de votre système d'information",
      subtitle: "Conception, UI, UX, architecture, idéation, nous imaginons les solutions pour digitaliser vos processus.",
    },
    {
      short: "IA",
      title: "Intelligence artificielle",
      subtitle: "Nous mettons en oeuvre les dernières avancées de l'intelligence artificielle pour disrupter votre business.",
    }
  ].map((skill, i) => ({
    buttons: [],
    title: skill.title,
    short: skill.short,
    subtitle: skill.subtitle,
    gradient:
        i % 3 === 0 ? "linear-gradient(90deg, rgba(129, 24, 243, 1), rgba(255, 0, 93, 0.85))" :
            i % 3 === 1 ?  "linear-gradient(90deg, rgba(255, 0, 93, 0.85),rgba(129, 24, 243, 0.8))" :
                "linear-gradient(90deg, rgba(255, 163, 0, 0.8), rgba(129, 24, 243, 1))",
    id: i
  }));

  const bottomBgWidth = Math.round(100/headers.length * 100)/100;

  useEffect(() => {
    const header = headers[headerIdx];
    const offset = Math.round(headers.length > 0 ? 20 * header.id / (headers.length - 1) : 0);
    setGradientPosition('translateX(-'+offset+'vw)');
    setBottomBgOffset((-100 + header.id * bottomBgWidth)+'vw');

    swiper && swiper.slideTo(headerIdx, 300);
    //swiper;
  }, [headerIdx, swiper])


  return (
    <header id="home" className={"Header"}>
      <div className="Header__container">
          <Swiper slidesPerView={1} onSwiper={setSwiper} onSlideChange={() => setHeaderIdx(swiper.activeIndex)}>
            {headers.map( (header, i) => <SwiperSlide key={i}>
              <div className="Header__text">
              <div className="Header__text-container">
                <h1 className="Header__title">
                  {header.title || ''}
                </h1>
                <h2 className="Header__subtitle">
                  {header.subtitle || ' '}
                </h2>
              </div>
              </div>
              </SwiperSlide>
            )}
          </Swiper>
      </div>

      <div className="Header__bottom">

        <div className={"Header__bottom-bg"} style={{left:bottomBgOffset}}>
          <div className={"Header__bottom-bg--white"}></div>
          <div className={"Header__bottom-bg--none"} style={{width:(bottomBgWidth+'vw')}}></div>
          <div className={"Header__bottom-bg--white"}></div>
        </div>

        {headers.map((item, idx) => {
          return (
            <div
              key = {item.id}
              className={"Header__big-button "+(idx === headerIdx && "Header__background-none")}
              onClick={() => {
                scrollTo(0, 0);
                setHeaderIdx(idx);
              }}
            >
              <span className="Header__application">
                {item.short}
              </span>
            </div>
          );
        })}
      </div>

      <svg
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid slice"
        style={{ "zIndex": "-10", position: "absolute", filter: "blur(2px)", background: headers[headerIdx].gradient, transform: gradientPosition }}>
        <defs>
          {/* App */}
          <radialGradient id="Gradient0" cx="50%" cy="50%" fx="10%" fy="50%" r=".5">
            <animate attributeName="fx" dur="34s" values="0%;3%;0%" repeatCount="indefinite"></animate>
            <stop offset="10%" stopColor="rgba(66, 17, 231, 0.8)"></stop>
            <stop offset="100%" stopColor="rgba(66, 17, 231, 0.0)"></stop>
          </radialGradient>
          {/* Data */}
          <radialGradient id="Gradient1" cx="50%" cy="50%" fx="10%" fy="50%" r=".5">
            <animate attributeName="fx" dur="34s" values="0%;3%;0%" repeatCount="indefinite"></animate>
            <stop offset="10%" stopColor="rgba(129, 24, 243, 0.8)"></stop>
            <stop offset="100%" stopColor="rgba(129, 24, 243, 0.0)"></stop>
          </radialGradient>
          {/* Web */}
          <radialGradient id="Gradient2" cx="50%" cy="50%" fx="10%" fy="50%" r=".5">
            <animate attributeName="fx" dur="34s" values="0%;3%;0%" repeatCount="indefinite"></animate>
            <stop offset="10%" stopColor="rgba(255, 163, 0, 0.8)"></stop>
            <stop offset="100%" stopColor="rgba(255, 163, 0, 0)"></stop>
          </radialGradient>
        </defs>

        <rect x="0" y="0" width="75%" height="75%" fill={"url(#Gradient" + (headers[headerIdx].id%3) + ")"}>
          <animate attributeName="x" dur="25s" values="25%;0%;25%" repeatCount="indefinite"></animate>
          <animate attributeName="y" dur="26s" values="0%;25%;0%" repeatCount="indefinite"></animate>
          <animateTransform attributeName="transform" type="rotate" from="0 50 50" to="360 50 50" dur="18s" repeatCount="indefinite"></animateTransform>
        </rect>
        <rect x="0" y="0" width="75%" height="75%" fill={"url(#Gradient" + headers[headerIdx].id + ")"}>
          <animate attributeName="x" dur="24s" values="-25%;0%;-25%" repeatCount="indefinite"></animate>
          <animate attributeName="y" dur="25s" values="0%;50%;0%" repeatCount="indefinite"></animate>
          <animateTransform attributeName="transform" type="rotate" from="0 50 50" to="360 50 50" dur="19s" repeatCount="indefinite"></animateTransform>
        </rect>
        <rect x="0" y="0" width="75%" height="75%" fill={"url(#Gradient" + headers[headerIdx].id + ")"}>
          <animate attributeName="x" dur="25s" values="0%;25%;0%" repeatCount="indefinite"></animate>
          <animate attributeName="y" dur="26s" values="15%;25%;15%" repeatCount="indefinite"></animate>
          <animateTransform attributeName="transform" type="rotate" from="360 50 50" to="0 50 50" dur="20s" repeatCount="indefinite"></animateTransform>
        </rect>
      </svg>
    </header>
  );
};

export default HomeHeader;
