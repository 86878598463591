import * as React from "react";
import "./Section.scss";

export type SectionProps = {
    id: string;
    background?: string;
    className?: string;
    baseline?: string;
    title?: string;
    children?: React.ReactNode|React.ReactNode[];
}
const Section = ({ id, background, className, baseline, title, children }:SectionProps) => {

  return <section
      id={id}
      className={"Section " + (className||"")}
      style={{backgroundColor: "var(" +( background || '--white' )+ ")"}}
  >
      <div className={"Section__content"}>
    {title && (
        <h2 className="Section__title" key={title}>
          {title}
          {baseline && (
              <p className="Section__desc" key={baseline}>
                {baseline}
              </p>
          )}
        </h2>
    )}
    {children}
      </div>
  </section>
};

export default Section;
