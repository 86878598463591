'use client';
import './MarqueeSection.scss';
import Section from "@/components/Section/Section";
import {useTranslation} from "@/i18n/client";
import {useLocale} from "@/hooks/locale-provider";

export default ({id, tokens}:{id:string,tokens:string[]}) => {
    const { t } = useTranslation('common');
    const locale = useLocale();

    return <Section id={id} className={"MarqueeSection"} background={"--light-grey"}>
        <div className={"MarqueeSection__title"}>Nous réalisons tout type d'applications</div>
        <div className={"MarqueeSection__marquee"}>
            <ul className={"MarqueeSection__marquee-content"}>
                {tokens.map((token, i) => <li key={i}>{token}</li>)}
            </ul>

            <ul className={"MarqueeSection__marquee-content"} aria-hidden={true}>
                {tokens.map((token, i) => <li key={i}>{token}</li>)}
            </ul>
        </div>
        <div className={"MarqueeSection__marquee MarqueeSection__marquee-reverse"}>
            <ul className={"MarqueeSection__marquee-content"}>
                {tokens.map((token, i) => <li key={i}>{token}</li>)}
            </ul>

            <ul className={"MarqueeSection__marquee-content"} aria-hidden={true}>
                {tokens.map((token, i) => <li key={i}>{token}</li>)}
            </ul>
        </div>
    </Section>
}