"use client";
import React, {useCallback, useEffect, useRef, useState} from "react";
import Section from "@/components/Section/Section";
import {useTranslation} from "@/i18n/client";
import {Team} from "@/gql/team";
import "./TeamsSection.scss";
import YzyPlayButton from "@/components/YzyPlayButton/YzyPlayButton";

export default ({id, team, baseline, title}:{
    id: string;
    team: Team[];
    baseline?: string;
    title: string;
}) => {


    const { t } = useTranslation('common');
    const gridElement = useRef(null);
    const [ selected, setSelected ] = useState(team[0].name);
    const [ selectedColIdx, setSelectedColIdx ] = useState(0);
    const [ gridTemplateColumns, setGridTemplateColumns ] = useState("1fr");
    const [ colTemplateRows, setColTemplateRows] = useState("1fr");
    const [ colTemplateCols, setColTemplateCols] = useState("1fr 1fr");
    const [ autoPlay, setAutoPlay] = useState(true);
    const [ cols, setCols] = useState<Team[][]>([]);
    const [ shuffledTeam, setShuffledTeam] = useState<Team[]>([]);

    const shuffle = useCallback(() => {

        const nb = window.innerWidth > 400 ? 7 : 3;
        const sT = [...team].sort((_a, _b) => 0.5 - Math.random()).slice(0,nb);
        setShuffledTeam(sT);

        const _cols: Team[][] = [];
        for(let i=0; i < sT.length; i+=2) {
            const remains = sT.length - i;
            const col =  sT.slice(i, remains <= 3 && i++ ? sT.length : i + 2);
            _cols.push(col);
        }
        setCols(_cols);

        setSelected(sT[Math.floor(sT.length * Math.random())].name);

    }, [setShuffledTeam, setCols])

    useEffect(() => {
        shuffle();
    }, [shuffle]);

    useEffect(() => {
        if(!gridElement.current) return;

        const observer = new IntersectionObserver(function (entries, observer) {
            entries.forEach((entry) => {
                setAutoPlay(entry.isIntersecting );
            });
        }, {
            root: null,
            threshold: 1
        });
        observer.observe(gridElement.current);

    }, [gridElement]);

    useEffect(() => {
        const selectedColIdx = cols.findIndex( col => !!col.find(el => el.name === selected));
        if(selectedColIdx === -1) return;
        const selectedCol = cols[selectedColIdx];
        const selectedRowIdx = selectedCol.findIndex(el => el.name === selected);

        setSelectedColIdx(selectedColIdx);
        // Mettre à jour le grid Template Columns
        setGridTemplateColumns(
            cols.map( (col,i) => i === selectedColIdx ? cols.length+'fr':col.length===3?'2fr':'1fr').join(' ')
        );
        setColTemplateRows(
        selectedRowIdx === selectedCol.length - 1 ? "1fr 4fr" : "4fr 1fr"
        );
        setColTemplateCols(
            selectedRowIdx === 2 ? "1fr 1fr" :
            selectedRowIdx === 0 && selectedCol.length === 3 ? "3fr 1fr":
            selectedRowIdx === 1 && selectedCol.length === 3 ? "1fr 3fr":
            "1fr"
        );
    }, [selected, cols])

    const handleSelect = useCallback((person:Team) => {
        setSelected(person.name);
        setAutoPlay(false);
    }, [setAutoPlay, setSelected]);

    useEffect(() => {
        if(!autoPlay) return;
        const timeout = setTimeout(() => {
            const personIdx = shuffledTeam.findIndex(p => p.name === selected);
            const randomIdx = (personIdx + 1) % team.length;
            if(!shuffledTeam[randomIdx]) return;
            setSelected(shuffledTeam[randomIdx].name);
        }, 3000);
        return () => clearTimeout(timeout);
    }, [selected, autoPlay, shuffledTeam]);



    return <Section id={id} title={title} baseline={baseline} className={"TeamSection"}>
        <YzyPlayButton className={"TeamSection__playButton"} onClick={() => shuffle()}/>
        <div className="TeamSection__grid"
             ref={gridElement}
             style={{gridTemplateColumns: gridTemplateColumns}}
             onMouseLeave={() => setAutoPlay(true)}>
            {cols.map( (col,i) => {
                const odd = i%2===1;
                return <div key={i} className={"TeamSection__col"+' '+(odd? "TeamSection__col-odd":'')} style={{
                            gridTemplateColumns: selectedColIdx === i ? colTemplateCols : col.length === 3 ? "1fr 1fr":"1fr",
                            gridTemplateRows: selectedColIdx === i ? colTemplateRows : odd ? "3fr 2fr" : "2fr 3fr"
                        }} >
                {col.map( (person, i) => <div
                    className={[
                        "TeamSection__person",
                        "TeamSection__person--"+person.color,
                        person.name === selected ? "TeamSection__person--selected":""
                    ].join(' ')}
                    key={person.name}
                    style={{
                        gridColumn: i === 2 ? 'span 2':'span 1'
                    }}
                    onClick={() => handleSelect(person)}
                >
                    <div className={"TeamSection__person-name"}>{person.name}</div>
                    <div className={"TeamSection__person-tag"}>{person.role.replaceAll("_"," ")}</div>
                    {person.name === selected && <div className={"TeamSection__person-description"}>{person.bio}</div>}
                </div>)}
            </div>})}
        </div>
    </Section>
}