"use client";
import React from "react";
import "./PostCard.scss";
import {Article} from "@/gql/articles";
import {useLocale} from "@/hooks/locale-provider";
import Link from "next/link";

const PostCard = ({post}: {
    post: Article
}) => {
    const locale = useLocale();

    return <Link href={`/${locale}/pages/${post?.seo?.canonicalURL}`}>
        <div className={"Post"}>
                <div className={"Post__Cover zoom-out"} style={{backgroundImage:"url("+post.banner.url+")"}}>
                </div>
                <div className={"Post__Legend"}>
                    <div className={"Post__Title"} style={{color:'var(--'+post.category.color+')'}}>{post.title}</div>
                    <div className={"Post__Date"}>{new Date(post.publishedAt).toLocaleDateString(locale, {
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric',
                    })}</div>
                    <div className={"Post__Short"}>{post.description||''}</div>
                </div>
            </div>
    </Link>
}

export default PostCard;