"use client";
import React from "react";
import Section from "@/components/Section/Section";
import {Swiper, SwiperSlide} from "swiper/react";
import {FreeMode, Navigation, Pagination} from "swiper/modules";
import Link from "next/link";
import {useTranslation} from "@/i18n/client";
import "./PostListSection.scss";
import PostCard from "@/components/PostCard/PostCard";

const COLORS = ['blue', 'orange', 'red'];

export default ({id, title, posts, baseline, background}: {
    id: string,
    title: string,
    posts: any[],
    baseline: string,
    background?: string
}) => {
    const { t, i18n } = useTranslation('common');


    return <Section id={id} title={title} baseline={baseline} background={background} className={"PostListSection"}>
        <Swiper pagination={{
            clickable: true
        }}
                slidesPerView={"auto"}
                freeMode={false}
                spaceBetween={75}
                slidesOffsetBefore={55}
                slidesOffsetAfter={55}
                modules={[FreeMode, Pagination]}>
            {posts.filter(a => !!(a?.seo?.canonicalURL)).map((post,i) => <SwiperSlide key={i}>
                <PostCard post={post}/>
            </SwiperSlide>)}
        </Swiper>
        <div className={"PostListSection__Buttons"}>
            <Link href={'/blog'} className={"button red"}>{t('see-more-news')}</Link>
        </div>
    </Section>
}