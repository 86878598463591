import React from "react";
import "./EditorJs.scss";

const JsonToHtml = (element:any, idx:number) => {
    switch (element.type) {
        case "paragraph":
            return <p key={idx} dangerouslySetInnerHTML={{ __html: element.data.text }} />
        case "table":
            return (
                <table key={idx} className={"table"}>
                    {element.data.content?.map((item:any, i:number) => (
                        <tr
                            key={i}
                            className={"tr"}
                        >
                            {i === 0 && element.data.withHeadings
                                ? item.map((el:any, i:number) => (
                                    <th
                                        key={i}
                                        className={"th"}
                                    >
                                        {el}
                                    </th>
                                ))
                                : item.map((el:any, i:number) => (
                                    <td
                                        key={i}
                                        className={"td"}
                                    >
                                        {el}
                                    </td>
                                ))}
                        </tr>
                    ))}
                </table>
            )
        case "list":
            if (element.data.style === "ordered") {
                return (
                    <ol key={idx} >
                        {element.data.items.map((item:any, i:number) => (
                            <li
                                key={i}
                                dangerouslySetInnerHTML={{ __html: item }}
                            />
                        ))}
                    </ol>
                )
            } else {
                return (
                    <ul  key={idx}  className={"unordered"}>
                        {element.data.items.map((item:any, i:number) => (
                            <li
                                key={i}
                                dangerouslySetInnerHTML={{ __html: item }}
                            />
                        ))}
                    </ul>
                )
            }
        case "image":
            return (
                <img
                    key={idx}
                    src={element.data.file.url}
                    alt={element.data.file.alt}
                    width={element.data.file.width}
                    height={element.data.file.height}
                />
            )

        case "header":
            switch (element.data.level) {
                case 1:
                    return <h1 key={idx} >{element.data.text}</h1>
                case 3:
                    return <h3 key={idx} >{element.data.text}</h3>
                case 4:
                    return <h4 key={idx} >{element.data.text}</h4>
                case 5:
                    return <h5 key={idx} >{element.data.text}</h5>
                case 6:
                    return <h6 key={idx} >{element.data.text}</h6>
                default:
                    return <h2 key={idx}  id={element.id}>{element.data.text}</h2>
            }
        case "checklist":
            return (
                <div key={idx} >
                    {element.data.items.map((item:any, index:number) => (
                        <div key={index}>
                            <input
                                value={item.text}
                                checked={item.checked}
                                type="checkbox"
                            />
                            <span>{item.text}</span>
                        </div>
                    ))}
                </div>
            )

        case "warning":
            return (
                <div key={idx}
                     className="warning"
                    title={element.data.title}
                >
                    <p>{element.data.message}</p>
                </div>
            )
        case "code":
            return (
                <pre key={idx} >
          <code>{element.data.code}</code>
        </pre>
            )
        case "raw":
            return <pre key={idx} >{element.data.html}</pre>

        case "quote":
            return (
                <blockquote key={idx}
                            cite=""
                    dangerouslySetInnerHTML={{ __html: element.data.text }}
                />
            )
        case "Col":
            return
        case "mediaLib":
            return
        default:
            throw new Error("Type not supported :" + element.type)
    }
}

const EditorJs = ({children}:{children:string}) => {

    const data = JSON.parse(children.replaceAll('&nbsp;',' '))?.blocks||[];

    return <div className="EditorJsContent">
        {    data.map((item:any,i:number) => {
            switch (item.type) {
                case "HeaderCol":
                    return <div key={i}></div>
                case "CentreCol":
                    return <div key={i}></div>
                case "FooterCol":
                    return <div key={i}></div>
                default:
                    return JsonToHtml(item, i);
            }
        })}
    </div>;
}

export default EditorJs;
