"use client";
import {useEffect, useState} from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import {Skill} from "@/gql/skills";
import Section from "@/components/Section/Section";
import Image from "next/image";
import EditorJs from "@/components/EditorJs/EditorJs";
import "./SkillsSection.scss"

export default ({id, skills, title, baseline}: {
    id: string,
    skills: Skill[],
    title: string,
    baseline: string
}) => {

    const [actualSlide, setActualSlide] = useState(0);
    const [swiper, setSwiper] = useState<any>(null);

    const updateSlide = () => {
        swiper && setActualSlide(swiper.activeIndex);
    }

    useEffect(() => {
        swiper && swiper.slideTo(actualSlide, 300);
    }, [swiper, actualSlide]);

    return <Section id={id} title={title} baseline={baseline}>
        <div className={"SkillsSection"}>
        {skills.map((skill, i) =>
            <div key={i}
                 className={"SkillsSection__Card"}>
                <div className={"SkillsSection__Header"}>
                    <Image src={skill.icon.url || "/images/marker.png"} alt={skill.title} width={64} height={64}/>
                    <span className={"SkillsSection__Title"}>{skill.title}</span>
                </div>
                <div className={"SkillsSection__Content"}>
                    <EditorJs>{skill.content}</EditorJs>
                </div>
            </div>
        )}
        </div>
    </Section>

    return <Section id={id} title={title} baseline={baseline} className={"SkillsSection"}>
        <div className={"SkillsSection__Body"}>
            <div className={"SkillsSection__Menu"}>
                <div className={"SkillsSection__Glider"} style={{
                    transform: "translateY("+actualSlide*100+'%)'
                }}></div>
                <ul>
                    {skills.map((skill, i) =>
                            <li key={i}
                                className={i === actualSlide?"SkillsSection__Selected":""}
                                onClick={() => setActualSlide(i)}>
                                <Image src={skill.icon.url || "/images/marker.png"} alt={skill.title} width={64} height={64}/>
                                <span className={"SkillsSection__ItemTitle"}>{skill.title}</span>
                            </li>
                    )}
                </ul>
            </div>
            <div className={"SkillsSection__Slider"}>
                <Swiper spaceBetween={0} slidesPerView={1} onSlideChange={updateSlide} onSwiper={setSwiper}>
                    {skills.map((skill,i) => <SwiperSlide key={i}>
                        <div className={"SkillsSection__Slide"} >
                        <h3>{skill.title}</h3>
                        <div className={"SkillsSection__Content"}>
                            <EditorJs>{skill.content}</EditorJs>
                        </div>
                        <div className={"SkillsSection__Features"}>
                            {skill.features.map((feature,j) => <div className={"SkillsSection__Feature"} key={j}>
                                {feature.icon && <img src={feature.icon.url} width={64} height={64} className={"SkillsSection__FeatureIcon"}/>}
                            </div>)}
                        </div>
                    </div>
                    </SwiperSlide>)}
                </Swiper>
            </div>
        </div>
    </Section>
}