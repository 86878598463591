'use client';
import React, {useCallback, useEffect, useRef, useState} from "react";
import Section from "@/components/Section/Section";
import Image from "next/image";
import dynamic from "next/dynamic";
import {Swiper, SwiperSlide} from "swiper/react";
import YzyPlayButton from "@/components/YzyPlayButton/YzyPlayButton";
import "./MapSection.scss";
import {useTranslation} from "@/i18n/client";
import {Location} from "@/gql/locations";
import {APIResult} from "@/components/MapSection/Map";

const Map = dynamic(() => import('./Map'), {ssr: false});

export default ({id, managerPicture, managerName, managerJob, managerWord, locations}: {
    id: string;
    managerPicture: string;
    managerName: string;
    managerJob: string;
    managerWord: string;
    locations: Location[]
}) => {
    const { t, i18n } = useTranslation('common');
    const [flipFlap, setFlipFlap] = useState(false);
    const [query, setQuery] = useState<string|undefined>(undefined);
    const [result, setResult] = useState<APIResult|undefined>(undefined);
    const input = useRef<HTMLInputElement>(null);
    const [swiper, setSwiper] = useState<any>(null);
    const [overEnabled, setOverEnabled] = useState(true);

    const clearResult = useCallback(() => {
        setResult(undefined);
        setQuery(undefined);
    }, []);

    const launchSearch = useCallback(() => {
        if(!input.current) return;
        setResult(undefined);
        setQuery(input.current.value);
    }, [input]);


    useEffect(() => {
        if(query) {
            const params = new URLSearchParams();
            params.set('locale', i18n.language);
            params.set('userLocation', query);
            fetch('/api/visit-us?'+params.toString()).then( res => {
                res.json().then((r) => {
                    setResult({
                        description: r.fancyText,
                        userLocation: [r.bestPath[0][0], r.bestPath[0][1]],
                        itinerary: r.itinerary,
                        bestPath: r.bestPath
                    })
                })
            });
        }
    }, [query]);


    useEffect(() => {
        if(!swiper) return;

        if(result) swiper.slideTo(2, 300);
        else if(query) swiper.slideTo(1, 300);
        else swiper.slideTo(0, 300);
    }, [swiper, result, query])

    return <Section id={id} title={t("yzydigital")}>
        <div className={"MapSection"}>
            {managerPicture &&
                <div className={"MapSection__Quote"+(flipFlap?" flipFlap":"")+(result?" MapSection__Quote--big":"")} >
                    <div className={"MapSection__Quote__Content"} >
                        <div className={"MapSection__Quote__Front"}
                             onMouseOver={() => {
                                 setOverEnabled(false);
                                 if(overEnabled) setFlipFlap(true);
                             }}>
                            <blockquote>
                                {managerWord.split("\n").map((line, i) => <p key={i}>{line}</p>)}
                                <cite>
                                    <Image src={managerPicture} alt={managerName+", "+managerJob} width={96} height={96}/>
                                    <span className={"MapSection__Author"}>
                                        {managerName}
                                        <span className={"MapSection__Job"}>{managerJob}</span>
                                    </span>
                                </cite>
                            </blockquote>
                            <YzyPlayButton className={"MapSection__playButton"} onClick={() => setFlipFlap(true)}/>
                        </div>
                        <div className={"MapSection__Quote__Back"}>
                            <button className={"MapSection__Quote__GoBack"} onClick={() => {
                                setResult(undefined);
                                setFlipFlap(false);
                                setQuery(undefined);
                            }}>&lt; {t("map-game-back")}</button>
                            <Swiper slidesPerView={1} onSwiper={setSwiper} >
                                <SwiperSlide>
                                    <p>
                                        {t("map-game-intro")}
                                    </p>
                                    <input id={"map-location"} type="text" placeholder={t("map-game-placeholder")} ref={input} autoComplete="off"/>
                                    <button className={"button"} onClick={() => launchSearch()}>{t("map-game-button")}</button>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className={"MapSection__Loader"}>
                                        <img src={"images/yzy-spinner.svg"} width={32} height={32} alt={"Chargement..."}/>
                                        <p>{t("map-game-loading-prefix")}</p>
                                        <div className={"MapSection__Loader__Words"}>
                                            <span>{t("map-game-loading-4")}</span>
                                            <span>{t("map-game-loading-1")}</span>
                                            <span>{t("map-game-loading-2")}</span>
                                            <span>{t("map-game-loading-3")}</span>
                                            <span>{t("map-game-loading-4")}</span>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    {result && <>
                                    {
                                        result.bestPath.length > 2 && <>
                                            <p>
                                                Itinéraire optimal identifié en {Math.round(parseInt(result.itinerary.routes[0].duration)/3600)} heures.
                                            </p>
                                            <p className={"MapSection__Result__Hashtags"}>
                                                #Google API #Optimisation tournée #ChatGPT
                                            </p>
                                        </>
                                    }

                                        <p className={"MapSection__IA_Title"}>
                                            Itinéraire "paysage" créatif alternatif #ChatGPT.
                                        </p>
                                        <p className={"MapSection__IA_Message"}>{result.description}</p>
                                        <button className={"button"} onClick={() => clearResult()}>{t("map-game-restart")}</button>
                                    </>}
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    </div>
                </div>
                }

            <div className={"MapSection__container"}>
                <Map locations={locations} result={result}/>
            </div>
        </div>
    </Section>
}