'use client';
import React, {useEffect} from "react";
import {useMenu} from "@/hooks/menu-provider";


const NavbarScrollWatcher = ({children}:{
    children: React.ReactNode
}) => {

    const {setActiveMenu, menuItems} = useMenu();

    useEffect(() => {
        const scrollWatcher = () => {
            let bestAnchor = null;

            for(const o of menuItems) {
                const el = document.getElementById(o.id)?.getBoundingClientRect();
                if(!el) continue;

                if(el.y > window.innerHeight * 3/4) continue;

                bestAnchor = o;

                if(el.y + el.height < window.innerHeight / 2) {
                    continue;
                }
                break;
            }
            setActiveMenu(bestAnchor ? bestAnchor.id : null);
        };
        window.addEventListener("scroll", scrollWatcher);
        return () => window.removeEventListener("scroll", scrollWatcher);
    }, []);

    return <>
        {children}
    </>
}

export default NavbarScrollWatcher;