'use client';
import {Swiper, SwiperSlide} from "swiper/react";
import {FreeMode, Navigation} from 'swiper/modules';
import './CustomerSection.scss';
import Section from "@/components/Section/Section";
import Link from "next/link";
import {Article} from "@/gql/articles";
import {useTranslation} from "@/i18n/client";
import {useLocale} from "@/hooks/locale-provider";

export default ({id, title, baseline, projects}: {
    id: string;
    title: string;
    baseline: string;
    projects: Article[]
}) => {
    const { t } = useTranslation('common');
    const locale = useLocale();

    return <Section id={id} title={title} baseline={baseline} background={"--light-grey"} className={"CustomerSection"}>
        <Swiper navigation={true} slidesPerView={"auto"} freeMode={true} spaceBetween={50} modules={[FreeMode, Navigation]} style={{width:"100%"}}>
            {projects.map((project,i) => <SwiperSlide key={i}>
                <div className={"CustomerSection__Cover"} style={{backgroundImage:"url("+project.banner?.formats.small.url+")"}}>
                    <div className={"CustomerSection__Overlay"}/>
                    <img src={"images/yzy-mask.svg"} alt={project.title} className={"CustomerSection__CoverMask"}></img>
                </div>
                <div className={"CustomerSection__Legend"}>
                    <div className={"CustomerSection__Title"}>{project.title}</div>
                    <div className={"CustomerSection__Tag"} style={{color:project.category?.color||'blue'}}>{project.category?.title}</div>
                    <div className={"CustomerSection__Short"}>{project?.seo?.keywords||''}</div>
                </div>
                <div>
                    <Link href={project.seo.canonicalURL} className={"button wired"}>Voir plus</Link>
                </div>
            </SwiperSlide>)}
        </Swiper>
    </Section>
}